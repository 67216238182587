import { type TFetchableData, type TFetchableResult } from '@/store/fetchableParts/types';
import { type TOptional } from '@/types/common';

import { type TPrefillInfo } from './stuff';

export const compareVersions = (v1: string, v2: string): number => {
  const t1 = Date.parse(v1);
  const t2 = Date.parse(v2);
  if (isNaN(t1) && isNaN(t2)) return 1;
  return (t1 || 0) - (t2 || 0);
};

export type TChooseRelevant = {
  relevant: TFetchableData;
  versions: TPrefillInfo;
};

const chooseRelevant = (
  fetched: TFetchableResult,
  previousVersions?: TOptional<TPrefillInfo>,
): TOptional<TChooseRelevant> => {
  const versions = {} as TPrefillInfo;
  const relevant = (fetched ? Object.keys(fetched) : []).reduce<TFetchableData>((acc, key) => {
    const name = key as keyof typeof fetched;
    const { data, error, version } = fetched[name]!;
    const prevVersion = previousVersions?.[name];
    if (!prevVersion || (version !== prevVersion && compareVersions(version, prevVersion) > 0)) {
      versions[name] = version;
      return { ...acc, [name]: data ?? error };
    }
    return acc;
  }, {} as TFetchableData);
  if (Object.keys(relevant).length) return { relevant, versions };
};

export default chooseRelevant;
