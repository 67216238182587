'use client';

import { Provider } from 'react-redux';

import { type TLayoutProps } from '@/app/_common/types';
import useRouteChangeObserver from '@/ducks/routeChange/useRouteChangeObserver';
import useHackForRouter from '@/ducks/routes/useHackForRouter';
import ForceRedirectGuard from '@/features/forceRedirect/Guard';
import RedirectGuard from '@/features/redirectGuard/Guard';
import { ApolloWrapper } from '@/helpers/api/graphql/ApolloWrapper';
import { loadApolloLogs } from '@/helpers/api/graphql/logs';
import { type TUrlParams } from '@/infra/types/common';
import { type TFetchableResult } from '@/store/fetchableParts/types';
import useSetupStore from '@/store/useSetupStore';

type TProps = TLayoutProps & {
  fetched?: TFetchableResult;
  urlParams?: TUrlParams;
};

const EarlyProviders = ({ children, fetched, urlParams }: TProps) => {
  const store = useSetupStore(fetched, urlParams);
  loadApolloLogs();
  useHackForRouter();
  useRouteChangeObserver();
  return (
    <Provider store={store}>
      <RedirectGuard>
        <ForceRedirectGuard>
          <ApolloWrapper>{children}</ApolloWrapper>
        </ForceRedirectGuard>
      </RedirectGuard>
    </Provider>
  );
};

export default EarlyProviders;
