import { routes } from '@/ducks/routes';
import { PromoBannerPlaceId } from '@/features/promoBanners/types';
import { type TRouteConfig } from '@/features/routeConfig/types';
import { CleanupItem } from '@/hooks/useResetPage';

const ROUTE_CONFIGS: Record<string, TRouteConfig> = {
  [`${routes.planner.chooseCabin.path}/*`]: {
    bannerPlaces: [
      PromoBannerPlaceId.chooseSubMetaC,
      PromoBannerPlaceId.chooseSubMetaD,
      PromoBannerPlaceId.chooseSubMetaE,
    ],
  },
  [routes.planner.chooseCabin.path]: {
    bannerPlaces: [PromoBannerPlaceId.chooseMetaC],
    cleanup: [CleanupItem.calculateInvoice],
  },
  [routes.planner.chooseVoyage.path]: {
    bannerPlaces: [
      PromoBannerPlaceId.itineraryResultsA,
      PromoBannerPlaceId.itineraryResultsB,
      PromoBannerPlaceId.itineraryResultsC,
    ],
    cleanup: [CleanupItem.calculateInvoice, CleanupItem.travelParty],
    nonAuthMnvv: { pickUrlParams: { withFilters: true }, redirectTo: routes.planner.chooseVoyage.path },
  },
  [routes.planner.confirmation.path]: {
    enterByLink: { redirectTo: routes.accounts.dashboard.path },
  },
  [routes.planner.fullCruiseDetails.path]: {
    bannerPlaces: [PromoBannerPlaceId.fullCruiseDetailsA],
    cleanup: [CleanupItem.calculateInvoice],
  },
  [routes.planner.payment.path]: {
    enterByLink: { pickUrlParams: { withFilters: true }, redirectTo: routes.planner.chooseVoyage.path },
  },
  [routes.planner.preCheckOut.path]: {
    bannerPlaces: [
      PromoBannerPlaceId.preCheckoutA,
      PromoBannerPlaceId.preCheckoutB,
      PromoBannerPlaceId.preCheckoutC,
      PromoBannerPlaceId.preCheckoutD,
    ],
    cleanup: [CleanupItem.calculateInvoice],
  },
  [routes.planner.summary.path]: {
    bannerPlaces: [
      PromoBannerPlaceId.summaryA,
      PromoBannerPlaceId.summaryB,
      PromoBannerPlaceId.summaryC,
      PromoBannerPlaceId.summaryD,
      PromoBannerPlaceId.summaryE,
    ],
  },
};

export default ROUTE_CONFIGS;
