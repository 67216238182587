import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';

import { SUMMARY_CALCULATE_INVOICE_CLEAR } from '@/constants/actionTypes';
import { selectCalculateInvoiceData } from '@/ducks/pages/summary/selectors';
import { selectTravelParty } from '@/ducks/travelParty/selectors';
import { resetTravelParty } from '@/ducks/travelParty/slice';
import useRouteConfig from '@/features/routeConfig/useRouteConfig';
import { type AppDispatch, type AppGetState, useAppDispatch } from '@/store';

export const enum CleanupItem {
  calculateInvoice = 'calculateInvoice',
  travelParty = 'travelParty',
}

const cleanupCalculateInvoice = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const data = selectCalculateInvoiceData(getState());
  if (!isEmpty(data)) dispatch({ type: SUMMARY_CALCULATE_INVOICE_CLEAR });
};

const cleanupTravelParty = () => (dispatch: AppDispatch, getState: AppGetState) => {
  const data = selectTravelParty(getState());
  if (!(isEmpty(data) || Object.values(data).every(isEmpty))) dispatch(resetTravelParty());
};

const useResetPage = () => {
  const dispatch = useAppDispatch();
  const routeConfig = useRouteConfig();

  useEffect(() => {
    routeConfig?.cleanup?.forEach((item) => {
      switch (item as CleanupItem) {
        case CleanupItem.calculateInvoice:
          dispatch(cleanupCalculateInvoice());
          break;
        case CleanupItem.travelParty:
          dispatch(cleanupTravelParty());
          break;
      }
    });
  }, [routeConfig]);
};

export default useResetPage;
